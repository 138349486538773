<template>
  <div class="acronis-prolong">
    <div class="acronis-prolong__header">
      <div class="acronis-prolong__title big-title">
        {{ $t('subtitle') }}
      </div>
    </div>
    <main-card>
      <div>{{ value.v }}</div>
    </main-card>
  </div>
</template>

<script>
import { isNumber } from 'lodash';
import { AcronisTariff } from '@/models/BillMgr/AcronisTariff';
export default {
  name: 'AcronisProlongSingle',
  props: {
    tariff: {
      type: AcronisTariff,
      required: true,
    },
    list: {
      type: Array,
      default: () => [],
      validator: arr => !arr.length || arr.every(i => i.k && isNumber(i.cost) && i.label),
    },
    value: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    innerList() {
      return [
        {
          title: this.title ? this.title : this.$t('title'),
          list: this.list,
          value: this.value,
          id: this.tariff.id,
        },
      ];
    },
  },
  methods: {
    onReady() {
      this.$emit('ready');
    },
    onChange(value) {
      this.$emit('change', value[this.tariff.id]);
    },
    onChangeTotal(value) {
      this.$emit('change-total', value);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Продление тарифа",
    "subtitle": "Облачный бэкап"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.acronis-prolong {
  &__header {
    +breakpoint(sm-and-up) {
      text-align: center;
    }
  }
  &__title + &__subtitle {
    margin-top: 1.25rem;
  }
}
</style>
